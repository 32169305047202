'use client'
import React, { useState, useEffect } from 'react'
import { signIn, signOut, useSession, getProviders } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import LinkComponent from '@components/LinkComponent'
import LocaleSwitcher from '@components/locale-switcher'
import MobileLocaleSwitcher from '@components/mobile-locale-switcher'
import MobileCurrencySwitcher from '@components/mobile-currency-switcher'
import MobileLanguageSetter from '@components/mobile-language-setter'
import { FaHome, FaSearch, FaHeart, FaUser } from "react-icons/fa"
import CurrencyTab from '@components/CurrencyTab'
import tailwindConfig from '@tailwind.config'
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation'
import { IoMenu, IoClose } from "react-icons/io5";
import { Dialog } from '@headlessui/react'
import { company } from '@constants/company'
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";

const Nav = ({ dictionary, lang, isViewPage = false }) => {
    const currentRoute = usePathname();
    const router = useRouter()

    const { data: session } = useSession()

    const [language, setLanguage] = useState(lang)
    const [providers, setProviders] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState('/')
    const [isMap, setIsMap] = useState(false)


    useEffect(() => {
        const setUpProviders = async () => {
            const response = await getProviders()

            setProviders(response)
        }

        setUpProviders()
    }, [])

    useEffect(() => {
        const setupUrl = () => {
            const url = currentRoute
            // console.log(url)
            setUrl(url)
        }

        setupUrl()
        router.refresh()
    }, [currentRoute])

  return (
    <nav className="w-full  bg-maincolor flex items-center justify-between flex-wrap p-0">
    <div className='w-full sm:flex hidden 2xl:w-8/12 items-center justify-between mx-auto p-5'>
        <div className='flex flex-row items-center text-light'>
            <LinkComponent href="/" className='relative w-[120px] mr-4'>
                    <Image
                        className='contain'
                        src={'/logo.png'}
                        width={206}
                        height={71}
                        alt="Logo"
                    // fill={true}
                    />
            </LinkComponent>
                <span>{company.navPhone}</span>
        </div>
        <div className="flex w-full items-center lg:w-auto">
            <CurrencyTab title="currency" lang={lang} />
            {/* <LocaleSwitcher language={language}/> */}
            <LinkComponent href="/">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.home}
                </span>
            </LinkComponent>
            <LinkComponent href="/properties">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.forSale}
                </span>
            </LinkComponent>
            <LinkComponent href="/properties?saleType=rent">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.forRent}
                </span>
            </LinkComponent>
            <LinkComponent href="/blogs">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.blogs}
                </span>
            </LinkComponent>
            <LinkComponent href="/sell">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.listWithUs}
                </span>
            </LinkComponent>
            <LinkComponent href="/contact-us">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.contactUs}
                </span>
            </LinkComponent>
            <LinkComponent href="/join-remax">
                <span className="block mt-4 lg:inline-block lg:mt-0 text-light hover:text-mainhover mr-4">
                    {dictionary.joinRemax}
                </span>
            </LinkComponent>
        </div>
    </div>
    {/* Mobile Nav */}
    <>
        <div className='sm:hidden w-full flex items-center justify-between relative p-1 pl-2 z-[120]'>
            {/* <div className='sm:hidden w-full flex flex-row justify-between relative p-2 px-2'> */}
            <div className='flex items-center'>
                <LinkComponent href={'/'}>
                    <div className='relative h-6 w-20'>
                        <Image
                            className='object-fit'
                            src={'/logo.png'}
                            alt="Logo"
                            width={250}
                            height={90}
                        />
                    </div>
                </LinkComponent>
            </div>
                <span className='text-light font-semibold'>+66 (0) 80 041 0420</span>
            {/* </div> */}
            {/* <MobileLanguageSetter language={lang} /> */}
            {/* <button onClick={() => setIsOpen(true)}>                    
                <IoMenu 
                    className='border-[1px] border-light rounded-md'
                    size={32}
                    color={tailwindConfig.theme.extend.colors.light}
                /></button>
            <Dialog 
                className='z-[120]' 
                open={isOpen} 
                onClose={() => setIsOpen(false)}
            >
                <div className="fixed  inset-0 bg-black/30 z-[120]" aria-hidden="true" />
                <Dialog.Panel className='bg-white w-[280px] z-[121] absolute top-0 right-0 rounded-l-md rounded-b-md'>
                    <div className='w-full flex justify-end p-1'>
                        <button onClick={() => setIsOpen(false)}>                    
                            <IoClose
                                className='border-[1px] border-light bg-maincolor rounded-md'
                                size={32}
                                color={tailwindConfig.theme.extend.colors.light}
                            />
                        </button>
                    </div>
                    <div className='p-2 w-full'>
                        <Dialog.Title className='text-maincolor pb-2 mb-2 border-b-[1px] border-forth'>
                            <LinkComponent href={`/`} className='hover:color-forth'>
                                {company.url}
                            </LinkComponent>
                        </Dialog.Title>
                        <div className='flex flex-col mb-2'>
                            <div className='sm:hidden flex'>
                                <MobileLocaleSwitcher language={lang} dictionary={dictionary} />
                            </div>
                        </div>
                        <div className='flex flex-col mb-2'>
                            <p className='text-sm text-secondary'>{dictionary.currency}</p>
                            <div className='sm:hidden flex'>
                                <MobileCurrencySwitcher lang={lang} />
                            </div>
                            
                        </div>
                        <div className='w-full bg-pink border-b-[1px] border-forth mb-2'></div>
                        <ul className='w-full flex text-maincolor flex-col mb-4 md:mb-0 md:pl-0'>
                            <LinkComponent href={`/about`} className='w-full hover:color-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.about}
                                </li>
                            </LinkComponent>
                            <LinkComponent href={`/contact-us`} className='w-full hover:text-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.contactUs}
                                </li>
                            </LinkComponent>
                            <LinkComponent href={`/new-listings`} className='w-full hover:text-forth'>
                                <li className='w-full my-1'>
                                        {dictionary.newestListings}
                                </li>
                            </LinkComponent>
                        </ul>
                    </div>
                </Dialog.Panel>
            </Dialog> */}
        </div>
        {!isMap && !isViewPage && (
            <div className='sm:hidden z-50 w-full bg-maincolor flex justify-between bottom-0 fixed border-t px-5 pt-2 pb-2 border-t-secondary'>
                <div>
                    <LinkComponent
                        href="/"
                        className='flex flex-col items-center justify-center'
                    >
                        {url == '/' || url == '/en' || url == '/th' || url == '/cn' ? (
                            <>
                                <FaHome
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}
                                />
                                <span className='text-light text-sm'>
                                    {dictionary.home}
                                </span>
                            </>
                        ) : (
                            <>
                                <FaHome
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.home}
                                </span>
                            </>
                        )}

                    </LinkComponent>
                </div>
                <div>
                    <LinkComponent
                        href="/properties"
                        className='flex flex-col items-center justify-center'
                    >
                        {url !== '/' && !url.includes('/profile') && !url.includes('/contact-us') && url !== '/' && url !== '/en' && url !== '/th' && url !== '/cn'  ? (
                            <>
                                <FaSearch
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}

                                />
                                <span className='text-light text-sm'>
                                    {dictionary.search}
                                </span></>
                        ) : (
                            <>
                                <FaSearch
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}

                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.search}
                                </span>
                            </>
                        )}

                    </LinkComponent>
                </div>
                <div>
                    <LinkComponent
                        href="/contact-us"
                        className='flex flex-col items-center justify-center'
                    >
                        {url.includes('/contact-us') ? (
                            <>
                                <MdEmail
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}
                                />
                                <span className='text-light text-sm'>
                                    Contact
                                </span>
                            </>
                        ) : (
                            <>
                                <MdEmail
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='text-forth text-sm'>
                                    Contact
                                </span>
                            </>
                        )}
                    </LinkComponent>
                </div>
                <div>
                    <Link
                        href={company.whatsappUrl}
                        className='flex flex-col items-center justify-center'
                    >
                        <>
                            <IoLogoWhatsapp
                                size={25}
                                color={'#25D366'}
                            />
                            <span className='text-forth text-sm'>
                                Whatsapp
                            </span>
                        </>
                    </Link>
                </div>

                {/* <div>
                    <LinkComponent
                        href="/likes"
                        className='flex flex-col items-center justify-center'
                    >
                        {url == '/likes' ? (
                            <>
                                <FaHeart
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.light}
                                />
                                <span className='text-light text-sm'>
                                    {dictionary.likes}
                                </span>
                            </>
                        ) : (
                            <>
                                <FaHeart
                                    size={25}
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='text-forth text-sm'>
                                    {dictionary.likes}
                                </span>
                            </>
                        )}
                    </LinkComponent>
                </div>
                <div>
                    {session?.user ? (
                        <LinkComponent
                            href="/profile"
                            className='flex flex-col items-center justify-center'
                        >
                            <Image
                                src={session?.user?.image}
                                alt='profile'
                                width={25}
                                height={25}
                                className='object-contain rounded-full'
                            />
                            {url == '/profile' ? (
                                <span className='text-light text-sm'>
                                    {dictionary.profile}
                                </span>
                            ) : (
                                <span className='text-forth text-sm'>
                                    {dictionary.profile}
                                </span>
                            )}
                        </LinkComponent>

                    ) : (
                        // <button onClick={() => signIn()}><span className='text-black'>Sign in</span></button>
                        <div
                            // href="/profile"
                            className='flex flex-col items-center justify-center'
                            onClick={() => signIn()}
                        >
                            {url == '/profile' ? (
                                <>
                                    <FaUser
                                        size={25}
                                        color={tailwindConfig.theme.extend.colors.light}
                                    />
                                    <span className='text-light text-sm'>
                                        {dictionary.profile}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <FaUser
                                        size={25}
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                    <span className='text-forth text-sm'>
                                        {dictionary.profile}
                                    </span>
                                </>
                            )}

                        </div>
                    )
                    }
                </div> */}
            </div>
        )}
    </>
</nav>
  )
}

export default Nav