export const company =
{
    name: 'RE/MAX Island Real Estate',
    welcomeName: 'buysamui.com',
    heroTitle: 'Your Koh Samui Property Solution',
    mainServiceArea: 'Koh Samui',
    phone1: '+66 (0) 80 041 0420',
    phone2: '+66 (0) 93 494 5999',
    navPhone: '+66 (0) 80 041 0420',
    email: 'islandrealestate@remax.co.th',
    address1: '1/39 Moo 6, Bophut Koh Samui',
    address2: 'Surat Thani, Thailand 84320',
    phoneUrl: 'tel:0800410420',
    lineUrl: 'https://line.me/ti/p/qpjgADG4bD',
    whatsappUrl: 'https://wa.me/66800410420',
    facebookUrl: 'https://www.facebook.com/remaxsamui',
    instagramUrl: 'https://www.instagram.com/remaxsamui',
    linkedInUrl: 'https://www.linkedin.com/company/remax-island-real-estate',
    tiktokUrl: 'https://www.tiktok.com/@remaxsamui'
}
